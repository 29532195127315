
var result = []

var all_routes = [
  {
    header: 'Dashboard',
    icon: 'BoxIcon',
    permission: '',
    children: [
      {
        title: 'Dashboard',
        route: 'dashboard',
        icon: 'ActivityIcon',
      }
    ]
  },
  {
    header: 'Orders',
    icon: 'BookIcon',
    permission: 'menu_orders',

    children: [
      {
        title: 'All Orders',
        route: 'orders',
        icon: 'ActivityIcon',
      },
      {
        title: 'Procssing Orders',
        route: 'processing-orders',
        icon: 'TrendingUpIcon',
      },
    ],
  },
  {
    header: 'Purchase',
    icon: 'BoxIcon',
    permission: 'menu_purchase',

    children: [
      {
        title: 'Purchasing',
        route: 'purchasing',
        icon: 'ActivityIcon',
      },
      {
        title: 'Purchase Orders',
        route: 'purchase-orders',
        icon: 'DiscIcon',
      },
    ],
  },
  {
    header: 'Products',
    icon: 'BriefcaseIcon',
    permission: 'menu_products',
    children: [
      {
        title: 'Products',
        route: 'products',
        icon: 'ActivityIcon',
      },
      {
        title: 'Create Listing',
        route: 'listing',
        icon: 'UploadIcon',
      },
    ],
  },
  {
    header: 'Expenses',
    icon: 'ActivityIcon',
    children: [
      {
        title: 'List',
        route: 'expenses-List',
        icon: 'ActivityIcon',
      },
      {
        title: 'Accounts',
        route: 'accounts',
        icon: 'ActivityIcon',
      },
      {
        title: 'Vector Invoice',
        route: 'incoming-invoice',
        icon: 'ActivityIcon',
      }
    ]
  },

  {
    header: 'Settings',
    icon: 'SettingsIcon',
    permission: 'menu_settings',
    children: [
      {
        title: 'Supplier Management',
        route: 'supplier-management',
        icon: 'ActivityIcon',
      },
      {
        title: 'User Management',
        route: 'user-management',
        icon: 'ActivityIcon',
      },
      {
        title: 'Roles Management',
        route: 'roles',
        icon: 'ActivityIcon',
      },
      {
        title: 'Category',
        route: 'category',
        icon: 'ActivityIcon',
      },
      {
        title: 'Roles Permission',
        route: 'role-permission',
        icon: 'ActivityIcon',
      }
    ]
  },

]

// if(User.role() ==  'admin'){
//   result =  routes
// }

// if(User.role() == 'manager'){

//   for (let i = 0; i < routes.length; i++) {

//     for (let j = 0; j <  routes[i].children.length; j++) {

//       if(routes[i].children[j].route=='user-management'){

//         routes[i].children.splice(j,1)
//       }
//     }
//   }
//   result = routes
// }
// // console.log('inside route file')
//export default result
for (let index = 0; index < all_routes.length; index++) {
  const route = all_routes[index];
  if (!checkPermission(route.permission)) {
    result.push(route)
  }


}

function checkPermission(permission) {
  let permissions = localStorage.getItem('user_permisions')
  permissions = permissions ? JSON.parse(permissions) : []
  return permissions.includes(permission)

}


let routes = result
export default routes

